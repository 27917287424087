import * as i from 'types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation, useI18next, Link as GatsbyLink } from 'gatsby-plugin-react-i18next';

import { validation } from 'services';
import { useAuthenticationActions } from 'services/hooks';
import { Input, PasswordInput } from 'common/form';
import { Heading, Text } from 'common/typography';
import { Button } from 'common/interaction';
import { PageTransition } from 'common/layout';
import { ArrowContainer } from 'modules/login';

import {
  ButtonContainer, HeaderContainer, StyledForm, PageWrapper,
} from './styled';

export const LoginForm: React.FC<LoginFormProps> = ({ location }) => {
  const { navigate } = useI18next();
  const { t } = useTranslation();
  const redirectUrl = location?.state?.redirectUrl || '/';

  const {
    register,
    setError,
    handleSubmit,
    clearErrors,
    formState: {
      isValid,
      errors,
    },
  } = useForm({ mode: 'onChange' });

  const { login } = useAuthenticationActions();

  const onSubmit = (data: i.UserLoginFormData) => {
    const { emailaddress, password } = data;

    login(emailaddress, password)
      .then(() => {
        clearErrors();
        navigate(redirectUrl);
      })
      .catch(() => {
        setError('emailaddress',
          { message: 'This combination of email address and password are not known in our system' });
      });
  };

  return (
    <PageTransition>
      <PageWrapper>
        <ArrowContainer onClick={() => navigate(redirectUrl)} />
        <HeaderContainer>
          <Heading margin="24px 0 75px" as="h2" variant={['gray', 'dark']}>
            {t('good to see you back again')}
          </Heading>
        </HeaderContainer>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Input
              label={t('emailaddress')}
              type="email"
              error={errors?.emailaddress}
              autoComplete="off"
              {...register('emailaddress', { ...validation.required, ...validation.email })}
            />
            <PasswordInput
              label={t('password')}
              error={errors.password}
              {...register('password',
                { ...validation.required })}
            />
            <Text $lineHeight="24" variant={['gray', 'dark']}>{t('forgot password')} &nbsp;
              <GatsbyLink to={'/requestPassword'}>{t('click here')}</GatsbyLink>
            </Text>
            <Text $lineHeight="24" variant={['gray', 'dark']}>{t('new here')} &nbsp;
              <GatsbyLink to={'/register'}>{t('signup')}</GatsbyLink>
            </Text>
          </div>
          <ButtonContainer>
            <Button
              type="submit"
              size="fullWidth"
              disabled={!isValid}
              $isValid={isValid}
            >
              Login
            </Button>
          </ButtonContainer>
        </StyledForm>
      </PageWrapper>
    </PageTransition>
  );
};

type LoginFormProps = {
  location: {
    state: {
      redirectUrl: string;
    };
  }
};
